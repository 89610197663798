"use client";
import { useEffect, useRef } from 'react';
import { SuperTokensWrapper } from 'supertokens-auth-react';
import SuperTokens from 'supertokens-web-js';
import { SuperTokensConfig } from '../config';
import useScript from '@/hooks/customScriptHook';

if (typeof window !== 'undefined') {
    SuperTokens.init(SuperTokensConfig);
}

const ClientSideWrapper = ({ children }) => {
    const targetRef = useRef(null);
    const gaStatus = useScript('https://www.google-analytics.com/analytics.js',10000);
    const ga4Status = useScript('https://www.googletagmanager.com/gtag/js?id=G-TK5NGBG6C7', 10000);
    useEffect(() => {
        const loadAnalytics = async () => {
          if (gaStatus === 'ready') {
            try {
              const ReactGA = await import('react-ga').then((mod) => mod.default);
              ReactGA.initialize('UA-78124262-1');
              ReactGA.pageview(window.location.pathname + window.location.search);
            } catch (error) {
              console.error("Failed to load Google Analytics", error);
            }
          }
    
          if (ga4Status === 'ready') {
            try {
              const ReactGA4 = await import('react-ga4').then((mod) => mod.default);
              ReactGA4.initialize('G-TK5NGBG6C7');
              ReactGA4.send('pageview');
            } catch (error) {
              console.error("Failed to load Google Analytics 4", error);
            }
          }
        };
    
        loadAnalytics();
      }, [gaStatus, ga4Status]);

    return (
        <>
            <div ref={targetRef} style={{ height: '1px', width: '1px' }}></div>
            <SuperTokensWrapper>{children}</SuperTokensWrapper>
        </>
    );
};

export default ClientSideWrapper;
